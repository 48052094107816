import { Icon, IconProps } from '@totejs/icons';

export const ReadyToClaimIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.3332 10.5C18.3332 15.1024 14.6022 18.8333 9.99984 18.8333C5.39746 18.8333 1.6665 15.1024 1.6665 10.5C1.6665 5.89763 5.39746 2.16667 9.99984 2.16667C14.6022 2.16667 18.3332 5.89763 18.3332 10.5Z"
        fill="#F0B90B"
      />
      <path
        d="M11.25 14.25C11.25 14.9404 10.6904 15.5 10 15.5C9.30964 15.5 8.75 14.9404 8.75 14.25C8.75 13.5596 9.30964 13 10 13C10.6904 13 11.25 13.5596 11.25 14.25Z"
        fill="#ffffff"
      />
      <path
        d="M8.96057 6.66959C8.92309 6.0358 9.39933 5.5 10.0002 5.5C10.601 5.5 11.0772 6.0358 11.0398 6.66959L10.7854 10.9703C10.7595 11.4086 10.4157 11.75 10.0002 11.75C9.58468 11.75 9.2408 11.4086 9.21488 10.9703L8.96057 6.66959Z"
        fill="#ffffff"
      />
    </Icon>
  );
};
