import { Flex, TabPanel, TabPanels, Tabs } from '@totejs/uikit';
import { ColoredWarningIcon } from '@totejs/icons';
import styled from '@emotion/styled';
import { useState, useCallback, useEffect } from 'react';
// import { useRouter } from 'next/router';
import { useTheme } from '@emotion/react';
import {
  MobileTab,
  mobileMedia,
  useResponsive,
  usePausedStatus,
  GA_MAP,
  reportEvent,
} from '@op-bridge/bridge-core';
import { useRouter } from 'next/router';

import { Container } from '../components/style';
import { useGetChainProviders } from '../hooks';
import { Loading } from '../components/Loading';
import { Withdraw } from '../components/Withdraw';
import { ReadyToClaim } from '../components/modal/ReadyToClaim';
import ThirdPartyBridge from '../components/ThirdPartyBridge';
import { TAB_MAP, WithdrawTabList } from '../components/withdraw/WithdrawTabList';
import { addDataIntoCache, getDataFromCache } from '../utils';

interface HistoryProps {
  type: string;
}

export default function Home(props: HistoryProps) {
  const { type } = props;
  const { l1Chain, l2Chain } = useGetChainProviders();
  const { pausedStatus, isPausedLoading } = usePausedStatus();
  const theme = useTheme() as any;
  const [tokenType, setTokenType] = useState('BNB');
  const { isMobile } = useResponsive();
  const [activeKey, setActiveKey] = useState('');
  const router = useRouter();

  const handleTokenType = useCallback((type: string) => {
    setTokenType(type);
  }, []);

  const getTabCache = useCallback(async () => {
    const cache = await getDataFromCache(window.location.hostname, {
      cacheName: 'withdrawTab',
      deleteCacheWhenTimeOut: false,
    });
    return cache;
  }, []);

  useEffect(() => {
    if (type && TAB_MAP.includes(type)) {
      setActiveKey(type);
    } else {
      getTabCache().then((res) => {
        if (res?.tab) {
          setActiveKey(res.tab);
        } else {
          // init tab
          setActiveKey('thirdParty');
        }
      });
    }
  }, [getTabCache, router?.asPath, type]);

  const onChange = async (key: string | number) => {
    const tabName = String(key);
    if (tabName === 'thirdParty') {
      if (typeof window !== 'undefined') {
        // Add tab status into cache
        await addDataIntoCache(window.location.hostname, { tab: 'thirdParty' }, 'withdrawTab');
      }
      reportEvent({
        name: GA_MAP.withdrawThirdPartyClick,
        data: { name: 'Withdrawal Third Party Click' },
      });
    } else {
      reportEvent({
        name: GA_MAP.withdrawOfficialClick,
        data: { name: 'Withdrawal Official Tab' },
      });
      if (typeof window !== 'undefined') {
        // Add tab status into cache
        await addDataIntoCache(window.location.hostname, { tab: 'official' }, 'withdrawTab');
      }
    }
    router.replace(`?type=${tabName}`);
  };

  return (
    <PageWrapper>
      <ReadyToClaim />
      {isMobile && <MobileTab />}
      <Container>
        <Wrapper>
          <Title>Withdraw</Title>
          {isPausedLoading === true ? (
            <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
              <Loading
                svgStyle={{
                  height: '40px',
                  width: '40px',
                  color: theme.colors.scene.primary.active,
                }}
              />
            </Flex>
          ) : pausedStatus === true ? (
            <Flex flexDirection={'column'} alignItems={'center'}>
              <ColoredWarningIcon
                mt={56}
                mb={24}
                width={80}
                height={80}
                color={'scene.warning.active'}
              />
              <WarningSubTitle>
                Withdrawal function temporarily suspended for some reason.
              </WarningSubTitle>
            </Flex>
          ) : !isPausedLoading ? (
            <>
              {!isMobile && (
                <SubTitle>
                  Transfer {activeKey === 'thirdParty' ? 'tokens' : tokenType} from{' '}
                  {l2Chain && l2Chain?.length > 0 && l2Chain[0].name} to{' '}
                  {l1Chain && l1Chain?.length > 0 && l1Chain[0].name}.
                </SubTitle>
              )}
              <CustomTabs variant="squared" activeKey={activeKey} onChange={onChange}>
                <WithdrawTabList />
                <TabPanels>
                  {activeKey === TAB_MAP[0] && (
                    <TabPanel panelKey={TAB_MAP[0]}>
                      <ThirdPartyBridge type="withdraw" />
                    </TabPanel>
                  )}
                  {activeKey === TAB_MAP[1] && (
                    <TabPanel panelKey={TAB_MAP[1]}>
                      <Withdraw handleTokenType={handleTokenType} tokenType={tokenType} />
                    </TabPanel>
                  )}
                </TabPanels>
              </CustomTabs>
            </>
          ) : null}
        </Wrapper>
      </Container>
    </PageWrapper>
  );
}

export async function getServerSideProps(context: any) {
  const { type } = context?.query;
  const rawType = TAB_MAP?.includes(type) ? type : '';

  return { props: { type: rawType } };
}

export const PageWrapper = styled(Flex)`
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: Inter;
  font-size: 24px;
  min-width: 515px;
  width: 100%;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
  text-align: center;
  color: ${(props: any) => props.theme.colors.readable.normal};
  ${mobileMedia} {
    min-width: 100%;
    margin-bottom: 24px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 24px;
  text-align: center;
  white-space: nowrap;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

const WarningSubTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  background: ${(props: any) => props.theme.colors.bg?.card};
  border-radius: 12px;
  box-shadow: ${(props: any) => props.theme.shadows.normal};
  padding: 48px 24px;
  max-width: 588px;
  min-height: 300px;
  position: relative;
  ${mobileMedia} {
    max-width: 100%;
    padding: 32px 12px;
  }
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
`;
