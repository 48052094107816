import { Button, Flex } from '@totejs/uikit';
import { useState, useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useAccount, useNetwork } from 'wagmi';
import { ReverseVIcon } from '@totejs/icons';
import { useRouter } from 'next/router';
import {
  SwitchNetworkButton,
  useDebounce,
  TokenObjectType,
  useCheckErc20TokenMatch,
  useGetPrice,
  useGetL1DataFee,
  useGetTokenList,
  useWithdrawConfirm,
  TxError,
  GA_MAP,
  reportEvent,
} from '@op-bridge/bridge-core';

import { ConnectButton } from './ConnectButton';
import { TokenSelection } from './TokenSelection';
import { BSCLogo } from './svgIcon/BSCLogo';
import * as env from '../env';
import {
  Container,
  FromText,
  IconWrapper,
  ChainName,
  InputWrapper,
  StyledInput,
  handleKeyPress,
  InputIcon,
  InputWaring,
  Arrow,
  Receive,
  ReceiveAmount,
  PriceWrapper,
  PriceText,
  PriceValue,
} from './Deposit';
import {
  useGetChainProviders,
  useWithdraw,
  useInputValidator,
  useGetGasPrice,
  useChainBalance,
  useERC20Withdraw,
} from '../hooks';
import { batchUpdate, removeTrailingZero } from '../utils';
import { Loading } from './Loading';
import { ConfirmWithdraw } from './modal/ConfirmWithdraw';
import { bep20TokenList } from '../env/bep20TokenList';
import ComboLogo from './svgIcon/ComboLogo';

const statusMap = {
  1: 'Amount is required.',
  2: 'Insufficient balance.',
  3: 'Please enter a minimum amount of 0.00000001.',
};

const min = 0.00000001;
export const Withdraw = ({
  handleTokenType,
  tokenType,
}: {
  handleTokenType: (type: string) => void;
  tokenType: string;
}) => {
  const { chain: currentChain } = useNetwork();
  const { isConnected, isConnecting, isDisconnected, isReconnecting } = useAccount();
  const router = useRouter();
  const {
    l1BalanceVal,
    l2BalanceVal,
    setL1BalanceVal,
    setL2BalanceVal,
    updateBalance,
    isLoading: isLoadingTokenBalance,
  } = useChainBalance(tokenType);

  const { showWithdrawalConfirm, handleWithdrawToggle, handleWithdrawConfirmShow } =
    useWithdrawConfirm();
  const { loadL1DataFee } = useGetL1DataFee();

  const { withdraw, isSufficient, txFailedMsg, showError, handleErrorShow } = useWithdraw();

  const {
    withdrawERC20,
    txFailedMsg: txERC20FailedMsg,
    showErrorErc20,
    handleErrorShowErc20,
  } = useERC20Withdraw();

  const { l1Chain, l2Chain } = useGetChainProviders();
  const { isLoadingPrice, isLoadingGasPrice, getEstimateGas, getEstimateERC20Gas, getGasPrice } =
    useGetGasPrice();
  const { status, changeVal, setMax } = useInputValidator(0, min);
  const { checkTokenAddress } = useCheckErc20TokenMatch(env.L2_RPC_URL);
  const { data: tokenPrice } = useGetPrice(tokenType);
  const { data: bnbPrice } = useGetPrice('BNB');
  const { tokensList } = useGetTokenList({
    tokenList: bep20TokenList,
    isNotUseGitHubToken: true,
  });

  const [txFailedMsgErc20, setTxFailedMsgErc20] = useState('');
  const [withdrawVal, setWithdrawVal] = useState('');
  const [estimateGas, setEstimateGas] = useState(0);
  const [l1DataGas, setL1DataGas] = useState(0);
  const [isCalcPrice, setIsCalcPrice] = useState(false);
  const [l2GasPrice, setL2GasPrice] = useState(0);
  const [l1GasPrice, setL1GasPrice] = useState(0);
  const [estimateGasError, setEstimateGasError] = useState('');

  setMax(l2BalanceVal ? Number(l2BalanceVal) : 0);
  const asset = useMemo(() => {
    return tokensList.filter((asset: TokenObjectType) => asset.symbol === tokenType);
  }, [tokenType, tokensList]);

  const calcPriceFee = useDebounce(async (value: string) => {
    try {
      setEstimateGasError('');
      const inputState = changeVal(Number(value));
      if (inputState === 0 && tokenType === 'BNB') {
        const SDKgas = await getEstimateGas(value, 'withdraw');
        const gasPrice = await getGasPrice(env.L2_RPC_URL);
        const l1GasPrice = await getGasPrice(env.L1_RPC_URL);
        // BNB to gwei
        if (SDKgas?.error) {
          setEstimateGasError(SDKgas?.msg);
          handleErrorShow(true);
          return;
        }
        if (SDKgas && SDKgas?.val && gasPrice) {
          const gasLimit = Number(SDKgas.val) * Math.pow(10, 18);
          // Calculate l1 data fee
          const l1DataFee = await loadL1DataFee();
          // eslint-disable-next-line no-console
          // console.log('l1 data fee:', Number(l1DataFee) * Math.pow(10, -18));

          batchUpdate(() => {
            setL1DataGas(Number(l1DataFee) * Math.pow(10, -18));
            setEstimateGas(gasLimit);
            setL2GasPrice(Number(gasPrice));
            setL1GasPrice(Number(l1GasPrice));
          });
          updateBalance();
        }
        setIsCalcPrice(false);
      } else if (inputState === 0 && asset && asset?.length) {
        // Check token match
        try {
          const pass = await checkTokenAddress(asset[0]);
          if (!pass) {
            throw Error('Your selected token is under maintenance, please try again later.');
          }
        } catch (e: any) {
          // eslint-disable-next-line no-console
          console.log(e);
          setEstimateGasError(e);
          handleErrorShow(true);
          setIsCalcPrice(false);
          return;
        }

        const SDKgas = await getEstimateERC20Gas(
          asset[0].l1Address,
          asset[0].l2Address,
          value,
          'withdraw',
        );
        const gasPrice = await getGasPrice(env.L2_RPC_URL);
        const l1GasPrice = await getGasPrice(env.L1_RPC_URL);
        // eslint-disable-next-line no-console
        // console.log('gas price: ', gasPrice, 'sdk gas', SDKgas);
        // const gasFee = Number(SDKgas) * Number(gasPrice);
        // BNB to gwei
        if (SDKgas?.error) {
          setEstimateGasError(SDKgas?.msg);
          handleErrorShow(true);
          return;
        }
        if (SDKgas && SDKgas?.val && gasPrice) {
          const l1DataFee = await loadL1DataFee();
          // eslint-disable-next-line no-console
          // console.log('l1 data fee:', Number(l1DataFee) * Math.pow(10, -18));

          batchUpdate(() => {
            setL1DataGas(Number(l1DataFee) * Math.pow(10, -18));
            setEstimateGas(Number(SDKgas?.val) * Math.pow(10, 18));
            setL2GasPrice(Number(gasPrice));
            setL1GasPrice(Number(l1GasPrice));
          });
          updateBalance();
        } else {
          // eslint-disable-next-line no-console
          console.log('error getting gas price', SDKgas, gasPrice);
        }
        setIsCalcPrice(false);
      } else {
        setIsCalcPrice(false);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
      setEstimateGasError(e);
      batchUpdate(() => {
        setEstimateGas(0);
        setL1DataGas(0);
        setIsCalcPrice(false);
      });
    }
  });

  useEffect(() => {
    if (isDisconnected) {
      batchUpdate(() => {
        setWithdrawVal('');
        setL1BalanceVal(0);
        setL2BalanceVal(0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected]);

  const handleChange = (e: React.ChangeEvent) => {
    let value = (e.target as HTMLInputElement).value;
    // update full stop to decimal in chinese typing
    value = value.replace('。', '.');
    value = value.replace(',', '.');
    try {
      const decimalLength = value.split('.')[1]?.length || 0;
      //Equal to zero, less than 0.00000001, more than 8 decimals or more than 20 digits
      if (
        (Number(value) !== 0 && Number(value) < min) ||
        value?.length > 20 ||
        decimalLength > 8 ||
        value.split('.').length > 2
      ) {
        return;
      }

      let filteredValue = value;
      if (value === '.') {
        filteredValue = '0.';
      }
      if (!isNaN(Number(filteredValue))) {
        setWithdrawVal(filteredValue);
      } else {
        setWithdrawVal('0');
      }
      // const decimalStart = /(?:^\.)|(?:^0(\d+))/g;
      // if (decimalStart.test(filteredValue)) {
      //   if (filteredValue[0] === '.') {
      //     filteredValue = filteredValue.replace('.', '');
      //   }
      //   if (filteredValue.split('.').length > 1) {
      //     filteredValue = Number(filteredValue.split('.')[0]) + '.' + filteredValue.split('.')[1];
      //   } else {
      //     filteredValue = String(parseInt(filteredValue));
      //   }

      //   filteredValue = filteredValue.replace(decimalStart, (...args) => {
      //     return args[1] || args[2] ? '0.' + (args[1] || args[2]) : '';
      //   });

      //   setWithdrawVal(filteredValue);
      // } else {
      //   if (!isNaN(Number(filteredValue))) {
      //     setWithdrawVal(filteredValue);
      //   }
      // }

      if (filteredValue && !isNaN(Number(filteredValue))) {
        if (Number(filteredValue) !== 0) {
          if (currentChain && currentChain.id === Number(env.L2_CHAIN_ID)) {
            setIsCalcPrice(true);
            calcPriceFee(filteredValue);
          }
        } else {
          setEstimateGas(0);
        }
      } else {
        // setEstimateGas(0);
        // setWithdrawVal('');
        changeVal(min);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleBlur = (e: any) => {
    const value = (e.target as HTMLInputElement).value;
    if (Number(value) === 0) {
      changeVal(0);
    }
    if (value[0] === '.') {
      const newValue = '0.' + value.split('.')[1];
      setWithdrawVal(newValue);
      if (Number(newValue) !== 0) {
        if (currentChain && currentChain.id === Number(env.L1_CHAIN_ID)) {
          setIsCalcPrice(true);
          calcPriceFee(newValue);
        }
      }
    }
  };

  const clearInput = useCallback(() => {
    setWithdrawVal('');
  }, []);

  const handleTokenSelection = useCallback(
    (tokenName: string) => {
      batchUpdate(() => {
        handleTokenType(tokenName);
        setWithdrawVal('');
      });
    },
    [handleTokenType],
  );

  let filteredList = tokensList;
  if (env.NET_ENV === 'Mainnet') {
    filteredList = tokensList.filter(
      (item) => item?.extensions?.officialBridgeStatus !== 'deprived',
    );
  }

  // console.log(
  //   'estimate gas',
  //   estimateGas,
  //   'l2 gas price',
  //   l2GasPrice,
  //   'l1 data gas',
  //   l1DataGas,
  //   'prove gas',
  //   env.PROVE_GAS[env.NET_ENV],
  //   'l1 gas price',
  //   l1GasPrice,
  // );

  return (
    <Container mt={24}>
      <Flex alignItems={'center'} mb={4}>
        <FromText>From</FromText>
        <IconWrapper>
          <ComboLogo width={15} height={15} type={'withdraw1'} />
        </IconWrapper>
        <ChainName>{l2Chain ? l2Chain[0]?.name : ''}</ChainName>
      </Flex>

      <Flex
        flexDirection={'column'}
        gap={4}
        gridGap={4}
        alignItems={'flex-start'}
        padding={'12px 16px'}
        background={'bg.codebox'}
        borderRadius={10}
      >
        <div>Amount</div>
        <InputWrapper>
          <StyledInput
            type={'text'}
            disabled={
              !isConnected ||
              !!isReconnecting ||
              !!isConnecting ||
              !currentChain ||
              currentChain.id !== Number(env.L2_CHAIN_ID)
            }
            value={withdrawVal}
            // pattern="[0-9]*"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            onWheel={(e: any) => e.target.blur()}
            onBlur={handleBlur}
            placeholder="0"
            className={status !== 0 ? 'error' : ''}
          ></StyledInput>
          <InputIcon>
            <TokenSelection
              value={tokenType}
              type="withdraw"
              assetList={filteredList}
              handleTokenSelection={handleTokenSelection}
              onMenuButtonClick={() => {
                reportEvent({
                  name: GA_MAP.withdrawTokenSelectionButton,
                  data: { name: 'Withdraw Token Selection' },
                });
              }}
              l1ChainId={env.L1_CHAIN_ID}
              l2ChainId={env.L2_CHAIN_ID}
              l1Rpc={env.L1_RPC_URL}
              l2Rpc={env.L2_RPC_URL}
              loadingComponent={<Loading />}
            ></TokenSelection>
          </InputIcon>
        </InputWrapper>
        {status > 0 ? (
          <InputWaring>{statusMap[status as keyof typeof statusMap]}</InputWaring>
        ) : null}
        <L2Balance>
          Balance:{' '}
          {!isLoadingTokenBalance
            ? `${removeTrailingZero(l2BalanceVal.toFixed(8))} ${tokenType}`
            : null}{' '}
          {l2BalanceVal && !isNaN(tokenPrice) && !isLoadingTokenBalance
            ? `($${Number(tokenPrice * Number(Number(l2BalanceVal))).toLocaleString('fullwide', {
                maximumFractionDigits: 2,
              })})`
            : null}
        </L2Balance>
      </Flex>

      <Flex flex={1} justifyContent={'center'} m={1}>
        <Arrow
          onClick={() => {
            reportEvent({ name: GA_MAP.withdrawSwitch, data: { name: 'Click Withdraw Switch' } });
            router.push('/deposit');
          }}
        >
          <ReverseVIcon width={16} height={16} color={'bg.middle'} />
        </Arrow>
      </Flex>

      <Flex alignItems={'center'} mb={4}>
        <FromText>To</FromText>
        <IconWrapper>
          <BSCLogo width={15} height={15} type={'withdraw'} />
        </IconWrapper>
        <ChainName>{l1Chain ? l1Chain[0]?.name : ''}</ChainName>
      </Flex>

      <Flex
        flexDirection={'column'}
        className="mobile-bottom-24"
        alignItems={'flex-start'}
        padding={16}
        background={'bg.codebox'}
        borderRadius={10}
        mb={[24, 16]}
      >
        <Receive>
          You will receive:{' '}
          <ReceiveAmount>
            {withdrawVal || 0} {tokenType}
          </ReceiveAmount>
        </Receive>

        <L1Balance>
          Current balance:{' '}
          {!isLoadingTokenBalance
            ? `${removeTrailingZero(l1BalanceVal.toFixed(8)) || 0} ${tokenType}`
            : null}{' '}
          {l1BalanceVal && !isNaN(tokenPrice) && !isLoadingTokenBalance
            ? `($${Number(tokenPrice * Number(l1BalanceVal)).toLocaleString('fullwide', {
                maximumFractionDigits: 2,
              })})`
            : ''}
        </L1Balance>
      </Flex>

      {withdrawVal &&
      Number(withdrawVal) !== 0 &&
      currentChain &&
      currentChain.id === Number(env.L2_CHAIN_ID) ? (
        <Flex
          className="mobile-bottom-24"
          gap={[0, 12, 12]}
          gridGap={[0, 12, 12]}
          flexDirection={'column'}
          mb={[24, 16]}
        >
          <PriceWrapper>
            <PriceText>Gas fee to initiate</PriceText>
            <PriceValue>
              {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice ? (
                `${removeTrailingZero((estimateGas * l2GasPrice + l1DataGas).toFixed(8))} ${
                  env.TOKEN_SYMBOL
                } (${
                  Number((estimateGas * l2GasPrice + l1DataGas) * bnbPrice) < 0.01
                    ? '< $0.01'
                    : `$${Number((estimateGas * l2GasPrice + l1DataGas) * bnbPrice).toLocaleString(
                        'fullwide',
                        {
                          maximumFractionDigits: 2,
                        },
                      )}`
                })`
              ) : (
                <Loading />
              )}
            </PriceValue>
          </PriceWrapper>
          <PriceWrapper>
            <PriceText>Gas fee to complete</PriceText>
            <PriceValue>
              {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice && bnbPrice ? (
                `${removeTrailingZero(
                  Number(
                    Number(
                      estimateGas * l2GasPrice +
                        l1DataGas +
                        Number((env.PROVE_GAS[env.NET_ENV] + 190000) * l1GasPrice),
                    ),
                  )?.toFixed(8),
                )} ${env.TOKEN_SYMBOL} ($${Number(
                  bnbPrice *
                    Number(
                      Number(
                        estimateGas * l2GasPrice +
                          l1DataGas +
                          Number((env.PROVE_GAS[env.NET_ENV] + 190000) * l1GasPrice),
                      ),
                    ),
                ).toLocaleString('fullwide', {
                  maximumFractionDigits: 2,
                })})`
              ) : (
                <Loading />
              )}
            </PriceValue>
          </PriceWrapper>
          <PriceWrapper>
            <PriceText>Estimated time of arrival</PriceText>
            <PriceValue>
              {env.NET_ENV === 'Mainnet' && '7 days'}
              {env.NET_ENV === 'Testnet' && '10 ~ 30 mins'}
            </PriceValue>
          </PriceWrapper>
        </Flex>
      ) : null}

      {isConnected &&
      !isConnecting &&
      currentChain &&
      currentChain.id === Number(env.L2_CHAIN_ID) ? (
        <WithdrawButton
          disabled={!isSufficient || !!status || !withdrawVal}
          onClick={() => {
            reportEvent({ name: GA_MAP.withdraw, data: { name: 'Click WithDraw' } });
            try {
              // showWithdrawalConfirm Pop modal
              handleWithdrawToggle();
            } catch (e) {
              //eslint-disable-next-line no-console
              console.log(e);
            }
            updateBalance();
          }}
        >
          Withdraw
        </WithdrawButton>
      ) : null}

      {isConnected &&
      !isReconnecting &&
      !isConnecting &&
      currentChain &&
      currentChain.id !== Number(env.L2_CHAIN_ID) ? (
        <SwitchNetworkButton id={Number(env.L2_CHAIN_ID)} />
      ) : null}

      {!isConnected && !isReconnecting && !isConnecting ? <ConnectButton type="withdraw" /> : null}

      {(txFailedMsg || estimateGasError) && (
        <TxError
          theme="light"
          isOpen={showError}
          title="Transaction Failed"
          handleOpen={() => handleErrorShow(false)}
          errorCode={txFailedMsg || estimateGasError} // txFailedMsg from useWithdraw
          clearInput={() => {
            // close error modal
            handleWithdrawConfirmShow(false);
            clearInput();
          }}
        />
      )}

      {/* Erc20 Modal */}
      {(txFailedMsgErc20 || txERC20FailedMsg) && (
        <TxError
          theme="light"
          isOpen={showErrorErc20}
          title="Transaction Failed"
          handleOpen={() => handleErrorShowErc20(false)}
          errorCode={txFailedMsgErc20 || txERC20FailedMsg} // txFailedMsgErc20 from confirmWithdrawPopup, txERC20FailedMsg from useERC20Withdraw
          clearInput={() => {
            clearInput();
            handleWithdrawConfirmShow(false);
          }}
        />
      )}

      {showWithdrawalConfirm ? (
        <ConfirmWithdraw
          tokenPrice={tokenPrice}
          l2GasPrice={l2GasPrice}
          l1GasPrice={l1GasPrice}
          l1DataGas={l1DataGas}
          estimateGas={estimateGas}
          bnbPrice={bnbPrice}
          withdrawERC20={withdrawERC20}
          isSufficient={isSufficient}
          setTxFailedMsgErc20={setTxFailedMsgErc20}
          withdrawFun={withdraw}
          withdrawVal={withdrawVal}
          asset={asset}
          tokenType={tokenType}
          isOpen={showWithdrawalConfirm}
          handleOpen={handleWithdrawConfirmShow}
          handleErrorShowErc20={handleErrorShowErc20}
          isLoadingPrice={isLoadingPrice}
          isLoadingGasPrice={isLoadingGasPrice}
          clearInput={clearInput}
          isCalcPrice={isCalcPrice}
        />
      ) : null}
    </Container>
  );
};

const L2Balance = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

const L1Balance = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;

const WithdrawButton = styled(Button)`
  line-height: 20px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors.bg?.card};
  &[disabled] {
    &,
    &:hover {
      color: ${(props: any) => props.theme.colors.readable?.secondary};
      background: ${(props: any) => props.theme.colors.readable?.disabled};
      opacity: 1;
    }
  }
`;

// const GasTooltip = styled(Tooltip)`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;
//   color: ${(props: any) => props.theme.colors.readable?.normal};
// `;
