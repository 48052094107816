import styled from '@emotion/styled';
import { Flex } from '@totejs/uikit';
import { useState, useEffect, useCallback } from 'react';
import { useAccount } from 'wagmi';
import Link from 'next/link';
import { mobileMedia, useWithdrawHistory, usePausedStatus } from '@op-bridge/bridge-core';

import { ReadyToClaimIcon } from '../svgIcon/ReadyToClaimIcon';

const pageSize = 20;
export const ReadyToClaim = () => {
  const { pausedStatus, isPausedLoading } = usePausedStatus();
  const { address: wagmiAddress, isConnected } = useAccount();

  const [historyList, setHistoryList] = useState([]);
  const [address, setAddress] = useState<string | null>(null);

  const {
    data: history,
    refetch,
    // isError: txError,
  } = useWithdrawHistory(address, 1, 100);

  let readyToWithdraw: any[] = [];
  historyList.forEach((tx: any, index: number) => {
    if (tx.receiptsStatus === 2 || tx.receiptsStatus === 4) {
      readyToWithdraw.push({ index, tx });
    }
  });
  const withdrawPage = Math.ceil((readyToWithdraw[0]?.index + 1) / pageSize) || 1;

  useEffect(() => {
    if (wagmiAddress) {
      setAddress(wagmiAddress);
    }
  }, [wagmiAddress]);

  useEffect(() => {
    if (history) {
      const list = history?.list || [];
      setHistoryList(list);
    }
  }, [history]);

  const refreshHistoryTable = useCallback(async () => {
    refetch()
      .then(async (res) => {
        const { data } = res;
        setHistoryList(data?.list);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [refetch]);

  useEffect(() => {
    let mount = true;
    if (isConnected && wagmiAddress && history && history?.total_num) {
      const requestAnimationFrameTimer = <RetT extends any>(
        fn: () => Promise<RetT>,
        time: number = 0,
      ) => {
        let now = Date.now;
        let startTime = now();
        let endTime = startTime;
        let uid;

        let timer = () => {
          if (mount) {
            uid = requestAnimationFrame(timer);
          }
          endTime = now();
          if (endTime - startTime >= time) {
            startTime = now();
            endTime = startTime;
            fn();
          }
        };
        uid = requestAnimationFrame(timer);
        return uid;
      };

      requestAnimationFrameTimer(refreshHistoryTable, 5000);
    }

    return () => {
      mount = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, wagmiAddress, history]);

  if (
    readyToWithdraw &&
    readyToWithdraw?.length > 0 &&
    isConnected &&
    !pausedStatus &&
    !isPausedLoading
  ) {
    return (
      <Container>
        <Flex className="leftContent" alignItems={'center'}>
          <ReadyToClaimIcon width={20} height={20} mr={8} ml={[8, 16]} />
          <ContentWrapper>
            <Title>Action Required</Title>
            <SubTitle>
              Open the Withdrawal History page to Prove or Finalize your withdrawals.
            </SubTitle>
          </ContentWrapper>
        </Flex>
        <LinkWrapper>
          <RedirectLink href={`/history?type=withdraw&p=${withdrawPage}`}>
            View History
          </RedirectLink>
        </LinkWrapper>
      </Container>
    );
  } else {
    return null;
  }
};

const Container = styled(Flex)`
  justify-content: space-between;
  height: 67px;
  padding: 0;
  border-radius: 8px;
  align-items: center;
  margin: 8px 32px -15px;
  background: ${(props: any) => props.theme.colors.bg.middle};
  .leftContent {
    height: 67px;
  }
  ${mobileMedia} {
    margin: 8px 12px 12px;
    padding: 0;
  }
  @media (max-width: 510px) {
    .leftContent {
      height: 82px;
    }
    height: 82px;
  }
`;

const LinkWrapper = styled.div`
  height: 67px;
  padding: 25px 12px;
  border-left: 1px solid ${(props: any) => props.theme.colors?.readable?.border};
  ${mobileMedia} {
    white-space: nowrap;
  }
`;

const RedirectLink = styled(Link)`
  color: ${(props: any) => props.theme.colors?.readable?.link};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  &:hover {
    color: ${(props: any) => props.theme.colors?.scene.primary.normal};
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  gap: 2px;
  color: ${(props: any) => props.theme.colors?.bg.card};
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: ${(props: any) => props.theme.colors?.readable?.secondary};
  ${mobileMedia} {
    margin-right: 8px;
  }
`;
